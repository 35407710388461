.hcontainer {
  background-color: #f4feff;
  height: 70svh;
}

.hcontent {
  max-width: 1200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 100%;
}

.btn a {
  color: #fff;
  text-decoration: none;
}

.dev {
  margin-right: 1rem;
}

.web {
  margin-left: 1rem;
  background: #306bac;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) {
  .hcontent {
    width: 390px;
  }
  .btn {
    width: 90vw;
  }
  .btn a {
    color: #fff;
    text-decoration: none;
  }
}
