.navbar-mob ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menu-mob {
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.4rem;
  margin-top: 3rem;
}

.btn_logout-mob {
  color: #4c4c4c !important;

  background-color: #fff;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 40px;
  padding: 5px 15px;
  border: 2px solid #fff;
}
.logo-mob {
  width: 8rem;
  margin-top: 2rem;
  margin-left: 40px;
}
