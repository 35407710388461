.clog {
  font-weight: 900;
  font-size: 2.2rem;
  color: #446df6;
}

.acontent {
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(6, 1fr);
  max-width: 1200px;
  gap: 50px;
  margin: 0 auto;
  padding: 50px 0;
  height: 100%;
}

.atext {
  grid-area: 1 / 1 / 2 / 5;
}

.description {
  line-height: 2em;
  color: #4c4c4c !important;
  grid-area: 2 / 1 / 3 / 5;
}
.btn_secundary {
  grid-area: 3 / 1 / 4 / 3;
  text-decoration: none;
}
.btn_secundary a {
  color: #fff;
  text-decoration: none;
}
.aprofile {
  grid-area: 1 / 5 / 3 / 7;
  border-radius: 100%;
  width: 100%;
  margin: auto 0;
  border: 8px solid #446df6;
  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
}

.servicesContainer {
  grid-area: 4 / 1 / 5 / 7;
  background-color: #44f6bd;
  border-radius: 15px;
  padding: 2rem 4rem;
}

.tag {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 600;
  color: #446df6;
  margin-top: 2rem;
}

.stack {
  grid-area: 2 / 1 / 3 / 13;
  display: flex;
  flex-direction: row;
  gap: 37px;
  margin: 40px 0 50px;
}

.stack > * {
  flex: 1;
}
.ssubtitle {
  text-transform: uppercase;
  color: #4c4c4c;

  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 0.5em;
}

span {
  color: #446df6;
  font-weight: bold;
  font-size: 2em;
}

p .description {
  line-height: 2em;
  color: #4c4c4c !important;
  /* width: 375px; */
}
.techs {
  grid-area: 5 / 1 / 6 / 7;
}
@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) {
  .acontent {
    width: 390px;
  }

  .aprofile {
    display: none;
  }

  .atext {
    grid-area: 1 / 1 / 2 / 7;
  }

  .description {
    grid-area: 2 / 1 / 3 / 7;
  }

  .btn_secundary a {
    color: #fff;
    text-decoration: none;
    text-align: center;
    grid-area: 3 / 1 / 4 / 7;
  }
}
