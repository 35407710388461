.ProjectDetail {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  max-width: 1200px;
  margin: 0 auto;
  column-gap: 37px;
  padding: 50px 0 5px;
  display: flex;
  justify-content: space-between;
}

.btn_allProjects {
  color: #446df6;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 5px 15px;
  width: 158px;
  height: 2rem;
  border: 2px solid #446df6;
}

.btn_allProjects:hover {
  border: 0;

  color: #fff;
  text-decoration: none;
  background-color: #905cff;
  padding: 5px 15px;
  border: 2px solid #905cff;
}

.ContentDetail {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  max-width: 1200px;
  margin: 0 auto;
  column-gap: 37px;
}
.tech {
  grid-area: 2 / 1 / 3 / 3;
  font-size: 0.6em;
  color: #8e8ea8;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.tech li {
  background: #d8d8d8;
  padding: 6px 8px;
  border-radius: 8px;
  font-size: 0.7rem;
}

.data {
  grid-area: 3 / 1 / 4 / 13;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 37px 0;
}

.description {
  color: #474747 !important;
  /* flex-grow: 1; */
}

.live {
  color: #474747;
  background: #f6e27f;
}

.live:hover {
  background: #d2bf60;
  border: 0;
  color: #474747;
}

.wimgl {
  grid-area: 4 / 1 / 5 / 13;
  width: 100%;
  margin-bottom: 37px;
  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
}

.wimgs {
  grid-area: 5 / 1 / 6 / 7;
  width: 100%;
  margin-bottom: 37px;

  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
}

.wimgxl {
  grid-area: 5 / 7 / 6 / 13;
  width: 100%;
  margin-bottom: 37px;

  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
}
.wimgm {
  grid-area: 6 / 1 / 7 / 13;
  width: 100%;
  margin-bottom: 37px;
  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
}

.wimgc {
  grid-area: 7 / 1 / 8 / 13;
  width: 100%;
  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
}
/* .prev {
  margin: 20px;
  grid-area: 7 / 1 / 8 / 2;
  width: 100%;
}
.next {
  grid-area: 7 / 12 / 8 / 13;
  width: 100%;
  margin: 20px;
} */

.btns {
  grid-area: 6 / 1 / 7 / 13;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin: 37px 0;
}

.prev {
  margin-left: 0 !important;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) {
  .ProjectDetail {
    width: 390px;
  }

  .data {
    width: 390px;

    padding: 20px;
    flex-direction: column;
  }
  .description {
    margin-bottom: 40px;
  }

  .imgl {
    width: 390px;
    padding: 20px;
    box-shadow: none;
    margin-bottom: 0;
  }
  .imgm {
    padding: 20px;
    width: 390px;
    box-shadow: none;
  }
  .imgs {
    grid-area: 6 / 1 / 7 / 13;
    width: 390px;
    padding: 20px;
    box-shadow: none;
  }

  .btn {
    text-align: center;
  }
}
