.project-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem 2rem 1rem 2rem;
  cursor: pointer;
}

.projectimg {
  width: 100%;
}

.project-container:hover {
  background: #f3f3f3;
}
.project-description {
  margin: 2rem 0;
}

.projecttitle {
  font-size: 1rem;
}

.projectdescription {
  font-size: 0.7rem;
}
