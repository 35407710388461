.container {
  background-color: #f4feff;
}

/* navbar styles */

.navbar {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  max-width: 1200px;
  gap: 37px;
  padding: 15px 0;
  margin: 0 auto;
}

ul {
  grid-area: 1 / 4 / 2 / 13;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.logo {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-area: 1 / 1 / 2 / 3;
}

li a {
  color: #2e2e2e;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.2rem;
  margin-left: 40px;
}

li a:hover {
  text-decoration: underline;
  text-decoration-color: #446df6;
  color: #446df6;

  text-decoration-thickness: 4px;
  text-underline-offset: 10px;
  transition-timing-function: ease-in;
  transition: 0.8s;
}

li a:active {
  text-decoration: underline;
  text-decoration-color: #446df6;
  color: #446df6;
}

.logot {
  display: flex;
  justify-content: flex-start;
  margin-left: 40px;
  cursor: pointer;
  text-decoration: underline;
  color: #446df6;
  font-size: 1.2rem;
  font-weight: 600;
}

.btn_logout {
  color: #446df6;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 40px;
  padding: 5px 15px;
  border: 2px solid #446df6;
}

.btn_logout:hover {
  border: 0;

  color: #fff;
  text-decoration: none;
  background-color: #446df6;
  padding: 5px 15px;
  border: 2px solid #446df6;
}
.bicon {
  width: 3rem;
  padding-left: 3rem;
  cursor: pointer;
  display: none;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) {
  .navbar {
    width: 390px;
    grid-template-columns: repeat(6, 1fr);
    gap: 5px;
    padding: 15px 0;
    margin: 0 auto;
  }

  .logo {
    width: 130px;
    grid-area: 1 / 1 / 2 / 3;
  }
  .menu {
    display: none;
  }
  .bicon {
    width: 3rem;
    cursor: pointer;
    display: block;
    grid-area: 1 / 5 / 2 / 7;
  }
  .btn_logout {
    display: none;
  }
}
