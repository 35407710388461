.ccontent {
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(12, 1fr);
  max-width: 1200px;
  gap: 37px;
  margin: 0 auto;
  padding: 50px 0;
  height: 100%;
}
.contact-form {
  margin: 5% auto;

  width: 588px;
  padding: 20px 0;
  border-radius: 14px;
  background: #fff;
  padding: 58px;
  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
  height: 770px;
}

.contact-form label {
  display: block;
  margin: 1.8rem auto;
}

.contact-form span {
  display: block;
  margin-bottom: 12px;
  color: #446df6;
}
.contact-form input,
textarea {
  padding: 8px 58px 0 30px;
  font-size: 1.2rem;
  color: #777;
  width: 498px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #b8c6f8;
  background: #f4feff;
}

textarea {
  height: 150px;
}

/* content */
.textcontent {
  grid-area: 1 / 1 / 2 / 8;
}
.contactimg {
  grid-area: 1 / 8 / 3 / 13;
  margin: auto 0;
  width: 480px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) {
  .contactimg {
    display: none;
  }
  .contact-form {
    padding: 20px;
    width: 390px;
    border-radius: none;
    background: none;
    box-shadow: none;
    margin: 0;
  }

  .contact-form input,
  textarea {
    width: 300px;
    background: #fff;
  }

  .textcontent {
    grid-area: 1 / 1 / 2 / 13;
    width: 390px;
  }
}
