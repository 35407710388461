/* navbar styles */

.fcontainer {
  /* position: fixed; */
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #f4feff;
}

/* navbar styles */

.footer {
  max-width: 1200px;
  padding: 2% 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.flogo {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
}
.footer .chuchos {
  color: #4c4c4c;
  font-size: 1rem;
}
.footer p {
  color: #4c4c4c;
  font-size: 1rem;
  font-weight: 500;
}
.copyright {
  text-align: right;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) {
  .footer {
    width: 390px;
    margin-bottom: 10%;
  }
  .contactData {
    text-align: right;
  }
  .copyright {
    display: none;
  }
}
