body {
  background-color: #f4feff;
}

.signup-form {
  margin: 5% auto;
  padding: 58px;
  border-radius: 14px;
  background: #fff;
  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
  width: 588px;
  height: 645px;
  flex-shrink: 0;
}

.signup-form label {
  display: block;
  margin: 1.8rem auto;
}

.signup-form span {
  display: block;
  margin-bottom: 12px;
}

.signup-form input {
  padding: 8px 58px 0 30px;
  font-size: 1.2rem;
  color: #777;
  width: 498px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #b8c6f8;
  background: #f4feff;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) {
  .signup-form {
    margin: 10% auto;
    padding: 20px;
    width: 390px;
    border-radius: none;
    background: none;
    box-shadow: none;
  }
  .signup-form input {
    color: #777;
    width: 300px;

    background: #fff;
  }
}
