.plcontent {
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(12, 1fr);
  max-width: 1200px;
  gap: 15px;
  margin: 0 auto;
  padding: 50px 0;
}

/* //card content */
.tech {
  color: #8e8ea8;
  margin-top: 30px;
  display: flex;
  margin-left: 5px;
  flex-direction: row;
}
.tech li {
  margin-left: 8px;
  padding: 6px 8px;
  border-radius: 8px;
}

.card h3 {
  color: #555;
  margin: 10px 20px;
}

.card p {
  color: #999;
  font-size: 0.9rem;
  line-height: 2em;
  margin: 10px 20px;
}

.card img {
  width: 100%;
}
.card a {
  text-decoration: none;
  margin: 10px 20px 30px;
  color: #4fc4cf;
}

.card {
  display: flex;
  flex-direction: column;
  margin: 10px;
  border-radius: 15px;
  background-color: #ffffff;
  align-items: flex-start;
  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
}
.link_intern {
  display: flex;
  flex: 1;
  align-items: flex-end;
  font-size: 0.9rem;
}
/* content */
.text {
  grid-area: 1 / 1 / 2 / 13;
  margin-bottom: 70px;
}
.card:nth-child(2) {
  grid-area: 2 / 1 / 3 / 4;
}

.card:nth-child(3) {
  grid-area: 2 / 4 / 3 / 7;
}
.card:nth-child(4) {
  grid-area: 2 / 7 / 3 / 10;
}

.card:nth-child(5) {
  grid-area: 2 / 10 / 3 / 13;
}

.card:nth-child(6) {
  grid-area: 3 / 1 / 4/ 4;
}

.card:nth-child(7) {
  grid-area: 3 / 4 / 4 / 7;
}

.card:nth-child(8) {
  grid-area: 3 / 7 / 4 / 10;
}

.card:nth-child(9) {
  grid-area: 3 / 10 / 4 / 13;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) {
  .plcontent {
    max-width: 390px;
    grid-template-columns: 1fr;
  }
  .tech {
    margin-left: 10px;
  }
  .tech li {
    margin-left: 12px;
    padding: 11px 13px !important;
    border-radius: 80px;
  }

  .card h3 {
    color: #555;
    margin: 24px 20px;
    font-size: 1.4rem;
  }

  .card p {
    font-size: 1.1rem;
  }

  .link_intern {
    font-size: 1.1rem;
  }
  .text {
    grid-area: 1 / 1 / 2 / 13;
    margin-bottom: 70px;
  }
  .card:nth-child(2) {
    grid-area: 2 / 1 / 3 / 13;
  }

  .card:nth-child(3) {
    grid-area: 3 / 1 / 4 / 13;
  }
  .card:nth-child(4) {
    grid-area: 4 / 1 / 5 / 13;
  }

  .card:nth-child(5) {
    grid-area: 5 / 1 / 6 / 13;
  }

  .card:nth-child(6) {
    grid-area: 6 / 1 / 7 / 13;
  }

  .card:nth-child(7) {
    grid-area: 7 / 1 / 8 / 13;
  }

  .card:nth-child(8) {
    grid-area: 8 / 1 / 9 / 13;
  }

  .card:nth-child(9) {
    grid-area: 9 / 1 / 10 / 13;
  }
}
