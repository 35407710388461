.library {
  position: fixed;
  top: 0;
  left: 0;
  width: 20rem;
  height: 100%;
  background: #446df6;
  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
  overflow: scroll;
  transform: translateX(-100%);
  transition: all 0.5s ease;
  opacity: 0;
}

.libraryh2 {
  padding: 2rem;
}

.selected {
  background-color: #f3f3f3;
}

.active-library {
  transform: translateX(0%);
  opacity: 1;
}
