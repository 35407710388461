@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
/* base styles */
body {
  font-family: 'Poppins', sans-serif;
  height: 100vh;
  margin: 0;
}
.App {
  font-size: 62.5%;
}
ul,
li,
p,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
  color: #4c4c4c;
  font-family: 'Poppins', sans-serif;
}
ul {
  list-style-type: none;
}
.btn {
  font-family: 'Poppins', sans-serif;
  background: #446df6;
  padding: 16px 36px;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  font-size: 1.25rem;
  border: none;
  text-decoration: none;
}

.btn_secundary {
  font-family: 'Poppins', sans-serif;
  background: #446df6;
  padding: 16px 36px;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  font-size: 1.25rem;
  border: none;
  text-decoration: none;
}

.btn:hover {
  background: #905cff;
  color: #fff;
}

.btn_secundary:hover {
  background: #905cff;
  color: #fff;
}
.btn:active {
  background: #4d0cd6;
  color: #fff;
}
.btn_secundary:active {
  background: #4d0cd6;
  color: #fff;
}
.title {
  font-family: 'Abril Fatface', sans-serif;
  font-weight: 900;
  font-size: 3.5rem;
  color: #446df6;
}
.subtitle {
  font-size: 3.6rem;
  color: #4c4c4c;
  font-weight: 700;
}

h3 {
  font-size: 1.2rem;
}

p {
  font-size: 1.2rem;
}

.link {
  color: #446df6;
}

.bajada {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1em;
  color: #4c4c4c;
}

label span {
  font-size: 1.125;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #446df6;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) {
  .title {
    width: 90%;
  }
  .btn,
  .btn_secundary {
    width: 90vw;
  }
}

.hcontainer {
  background-color: #f4feff;
  height: 70svh;
}

.hcontent {
  max-width: 1200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 100%;
}

.btn a {
  color: #fff;
  text-decoration: none;
}

.dev {
  margin-right: 1rem;
}

.web {
  margin-left: 1rem;
  background: #306bac;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) {
  .hcontent {
    width: 390px;
  }
  .btn {
    width: 90vw;
  }
  .btn a {
    color: #fff;
    text-decoration: none;
  }
}

.clog {
  font-weight: 900;
  font-size: 2.2rem;
  color: #446df6;
}

.acontent {
  display: grid;
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(6, 1fr);
  max-width: 1200px;
  grid-gap: 50px;
  gap: 50px;
  margin: 0 auto;
  padding: 50px 0;
  height: 100%;
}

.atext {
  grid-area: 1 / 1 / 2 / 5;
}

.description {
  line-height: 2em;
  color: #4c4c4c !important;
  grid-area: 2 / 1 / 3 / 5;
}
.btn_secundary {
  grid-area: 3 / 1 / 4 / 3;
  text-decoration: none;
}
.btn_secundary a {
  color: #fff;
  text-decoration: none;
}
.aprofile {
  grid-area: 1 / 5 / 3 / 7;
  border-radius: 100%;
  width: 100%;
  margin: auto 0;
  border: 8px solid #446df6;
  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
}

.servicesContainer {
  grid-area: 4 / 1 / 5 / 7;
  background-color: #44f6bd;
  border-radius: 15px;
  padding: 2rem 4rem;
}

.tag {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 600;
  color: #446df6;
  margin-top: 2rem;
}

.stack {
  grid-area: 2 / 1 / 3 / 13;
  display: flex;
  flex-direction: row;
  grid-gap: 37px;
  gap: 37px;
  margin: 40px 0 50px;
}

.stack > * {
  flex: 1 1;
}
.ssubtitle {
  text-transform: uppercase;
  color: #4c4c4c;

  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 0.5em;
}

span {
  color: #446df6;
  font-weight: bold;
  font-size: 2em;
}

p .description {
  line-height: 2em;
  color: #4c4c4c !important;
  /* width: 375px; */
}
.techs {
  grid-area: 5 / 1 / 6 / 7;
}
@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) {
  .acontent {
    width: 390px;
  }

  .aprofile {
    display: none;
  }

  .atext {
    grid-area: 1 / 1 / 2 / 7;
  }

  .description {
    grid-area: 2 / 1 / 3 / 7;
  }

  .btn_secundary a {
    color: #fff;
    text-decoration: none;
    text-align: center;
    grid-area: 3 / 1 / 4 / 7;
  }
}

.plcontent {
  display: grid;
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(12, 1fr);
  max-width: 1200px;
  grid-gap: 15px;
  gap: 15px;
  margin: 0 auto;
  padding: 50px 0;
}

/* //card content */
.tech {
  color: #8e8ea8;
  margin-top: 30px;
  display: flex;
  margin-left: 5px;
  flex-direction: row;
}
.tech li {
  margin-left: 8px;
  padding: 6px 8px;
  border-radius: 8px;
}

.card h3 {
  color: #555;
  margin: 10px 20px;
}

.card p {
  color: #999;
  font-size: 0.9rem;
  line-height: 2em;
  margin: 10px 20px;
}

.card img {
  width: 100%;
}
.card a {
  text-decoration: none;
  margin: 10px 20px 30px;
  color: #4fc4cf;
}

.card {
  display: flex;
  flex-direction: column;
  margin: 10px;
  border-radius: 15px;
  background-color: #ffffff;
  align-items: flex-start;
  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
}
.link_intern {
  display: flex;
  flex: 1 1;
  align-items: flex-end;
  font-size: 0.9rem;
}
/* content */
.text {
  grid-area: 1 / 1 / 2 / 13;
  margin-bottom: 70px;
}
.card:nth-child(2) {
  grid-area: 2 / 1 / 3 / 4;
}

.card:nth-child(3) {
  grid-area: 2 / 4 / 3 / 7;
}
.card:nth-child(4) {
  grid-area: 2 / 7 / 3 / 10;
}

.card:nth-child(5) {
  grid-area: 2 / 10 / 3 / 13;
}

.card:nth-child(6) {
  grid-area: 3 / 1 / 4/ 4;
}

.card:nth-child(7) {
  grid-area: 3 / 4 / 4 / 7;
}

.card:nth-child(8) {
  grid-area: 3 / 7 / 4 / 10;
}

.card:nth-child(9) {
  grid-area: 3 / 10 / 4 / 13;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) {
  .plcontent {
    max-width: 390px;
    grid-template-columns: 1fr;
  }
  .tech {
    margin-left: 10px;
  }
  .tech li {
    margin-left: 12px;
    padding: 11px 13px !important;
    border-radius: 80px;
  }

  .card h3 {
    color: #555;
    margin: 24px 20px;
    font-size: 1.4rem;
  }

  .card p {
    font-size: 1.1rem;
  }

  .link_intern {
    font-size: 1.1rem;
  }
  .text {
    grid-area: 1 / 1 / 2 / 13;
    margin-bottom: 70px;
  }
  .card:nth-child(2) {
    grid-area: 2 / 1 / 3 / 13;
  }

  .card:nth-child(3) {
    grid-area: 3 / 1 / 4 / 13;
  }
  .card:nth-child(4) {
    grid-area: 4 / 1 / 5 / 13;
  }

  .card:nth-child(5) {
    grid-area: 5 / 1 / 6 / 13;
  }

  .card:nth-child(6) {
    grid-area: 6 / 1 / 7 / 13;
  }

  .card:nth-child(7) {
    grid-area: 7 / 1 / 8 / 13;
  }

  .card:nth-child(8) {
    grid-area: 8 / 1 / 9 / 13;
  }

  .card:nth-child(9) {
    grid-area: 9 / 1 / 10 / 13;
  }
}




.ProjectDetail {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  max-width: 1200px;
  margin: 0 auto;
  grid-column-gap: 37px;
  -webkit-column-gap: 37px;
          column-gap: 37px;
  padding: 50px 0 5px;
  display: flex;
  justify-content: space-between;
}

.btn_allProjects {
  color: #446df6;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 5px 15px;
  width: 158px;
  height: 2rem;
  border: 2px solid #446df6;
}

.btn_allProjects:hover {
  border: 0;

  color: #fff;
  text-decoration: none;
  background-color: #905cff;
  padding: 5px 15px;
  border: 2px solid #905cff;
}

.ContentDetail {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  max-width: 1200px;
  margin: 0 auto;
  grid-column-gap: 37px;
  -webkit-column-gap: 37px;
          column-gap: 37px;
}
.tech {
  grid-area: 2 / 1 / 3 / 3;
  font-size: 0.6em;
  color: #8e8ea8;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.tech li {
  background: #d8d8d8;
  padding: 6px 8px;
  border-radius: 8px;
  font-size: 0.7rem;
}

.data {
  grid-area: 3 / 1 / 4 / 13;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 37px 0;
}

.description {
  color: #474747 !important;
  /* flex-grow: 1; */
}

.live {
  color: #474747;
  background: #f6e27f;
}

.live:hover {
  background: #d2bf60;
  border: 0;
  color: #474747;
}

.wimgl {
  grid-area: 4 / 1 / 5 / 13;
  width: 100%;
  margin-bottom: 37px;
  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
}

.wimgs {
  grid-area: 5 / 1 / 6 / 7;
  width: 100%;
  margin-bottom: 37px;

  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
}

.wimgxl {
  grid-area: 5 / 7 / 6 / 13;
  width: 100%;
  margin-bottom: 37px;

  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
}
.wimgm {
  grid-area: 6 / 1 / 7 / 13;
  width: 100%;
  margin-bottom: 37px;
  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
}

.wimgc {
  grid-area: 7 / 1 / 8 / 13;
  width: 100%;
  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
}
/* .prev {
  margin: 20px;
  grid-area: 7 / 1 / 8 / 2;
  width: 100%;
}
.next {
  grid-area: 7 / 12 / 8 / 13;
  width: 100%;
  margin: 20px;
} */

.btns {
  grid-area: 6 / 1 / 7 / 13;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin: 37px 0;
}

.prev {
  margin-left: 0 !important;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) {
  .ProjectDetail {
    width: 390px;
  }

  .data {
    width: 390px;

    padding: 20px;
    flex-direction: column;
  }
  .description {
    margin-bottom: 40px;
  }

  .imgl {
    width: 390px;
    padding: 20px;
    box-shadow: none;
    margin-bottom: 0;
  }
  .imgm {
    padding: 20px;
    width: 390px;
    box-shadow: none;
  }
  .imgs {
    grid-area: 6 / 1 / 7 / 13;
    width: 390px;
    padding: 20px;
    box-shadow: none;
  }

  .btn {
    text-align: center;
  }
}

.ProjectDetail {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  max-width: 1200px;
  margin: 0 auto;
  grid-column-gap: 37px;
  -webkit-column-gap: 37px;
          column-gap: 37px;
  padding: 50px 0 5px;
  display: flex;
  justify-content: space-between;
}

.btn_allProjects {
  color: #446df6;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 5px 15px;
  width: 158px;
  height: 2rem;
  border: 2px solid #446df6;
}

.btn_allProjects:hover {
  border: 0;

  color: #fff;
  text-decoration: none;
  background-color: #905cff;
  padding: 5px 15px;
  border: 2px solid #905cff;
}

.ContentDetail {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  max-width: 1200px;
  margin: 0 auto;
  grid-column-gap: 37px;
  -webkit-column-gap: 37px;
          column-gap: 37px;
}
.tech {
  grid-area: 2 / 1 / 3 / 3;
  font-size: 0.6em;
  color: #8e8ea8;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.tech li {
  background: #d8d8d8;
  padding: 6px 8px;
  border-radius: 8px;
  font-size: 0.7rem;
}

.data {
  grid-area: 3 / 1 / 4 / 13;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 37px 0;
}

.description {
  color: #474747 !important;
  /* flex-grow: 1; */
}

.live {
  color: #474747;
  background: #f6e27f;
}

.live:hover {
  background: #d2bf60;
  border: 0;
  color: #474747;
}

.imgl {
  grid-area: 4 / 1 / 5 / 13;
  width: 100%;
  margin-bottom: 37px;
  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
}

.imgm {
  grid-area: 5 / 1 / 6 / 7;
  width: 100%;
  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
}
.imgs {
  grid-area: 5 / 7 / 6 / 13;
  width: 100%;
  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
}

/* .prev {
  margin: 20px;
  grid-area: 7 / 1 / 8 / 2;
  width: 100%;
}
.next {
  grid-area: 7 / 12 / 8 / 13;
  width: 100%;
  margin: 20px;
} */

.btns {
  grid-area: 6 / 1 / 7 / 13;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin: 37px 0;
}

.prev {
  margin-left: 0 !important;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) {
  .ProjectDetail {
    width: 390px;
  }

  .data {
    width: 390px;

    padding: 20px;
    flex-direction: column;
  }
  .description {
    margin-bottom: 40px;
  }

  .imgl {
    width: 390px;
    padding: 20px;
    box-shadow: none;
    margin-bottom: 0;
  }
  .imgm {
    padding: 20px;
    width: 390px;
    box-shadow: none;
  }
  .imgs {
    grid-area: 6 / 1 / 7 / 13;
    width: 390px;
    padding: 20px;
    box-shadow: none;
  }

  .btn {
    text-align: center;
  }
}

.ccontent {
  display: grid;
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(12, 1fr);
  max-width: 1200px;
  grid-gap: 37px;
  gap: 37px;
  margin: 0 auto;
  padding: 50px 0;
  height: 100%;
}
.contact-form {
  margin: 5% auto;

  width: 588px;
  padding: 20px 0;
  border-radius: 14px;
  background: #fff;
  padding: 58px;
  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
  height: 770px;
}

.contact-form label {
  display: block;
  margin: 1.8rem auto;
}

.contact-form span {
  display: block;
  margin-bottom: 12px;
  color: #446df6;
}
.contact-form input,
textarea {
  padding: 8px 58px 0 30px;
  font-size: 1.2rem;
  color: #777;
  width: 498px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #b8c6f8;
  background: #f4feff;
}

textarea {
  height: 150px;
}

/* content */
.textcontent {
  grid-area: 1 / 1 / 2 / 8;
}
.contactimg {
  grid-area: 1 / 8 / 3 / 13;
  margin: auto 0;
  width: 480px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) {
  .contactimg {
    display: none;
  }
  .contact-form {
    padding: 20px;
    width: 390px;
    border-radius: none;
    background: none;
    box-shadow: none;
    margin: 0;
  }

  .contact-form input,
  textarea {
    width: 300px;
    background: #fff;
  }

  .textcontent {
    grid-area: 1 / 1 / 2 / 13;
    width: 390px;
  }
}

body {
  background-color: #f4feff;
}
.login-form {
  margin: 5% auto;

  padding: 58px;
  border-radius: 14px;
  background: #fff;
  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
  width: 588px;
  height: 514px;
  flex-shrink: 0;
}

.login-form label {
  display: block;
  margin: 1.8rem auto;
}

.login-form span {
  display: block;
  margin-bottom: 12px;
}

.login-form input {
  padding: 8px 58px 0 30px;
  font-size: 1.2rem;
  color: #777;
  width: 498px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #b8c6f8;
  background: #f4feff;
}

.btn {
  /* margin-top: 20px; */
  float: right;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) {
  .login-form {
    margin: 10% auto;
    padding: 20px;
    width: 390px;
    border-radius: none;
    background: none;
    box-shadow: none;
  }
  .login-form input {
    color: #777;
    width: 300px;

    background: #fff;
  }
}

body {
  background-color: #f4feff;
}

.signup-form {
  margin: 5% auto;
  padding: 58px;
  border-radius: 14px;
  background: #fff;
  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
  width: 588px;
  height: 645px;
  flex-shrink: 0;
}

.signup-form label {
  display: block;
  margin: 1.8rem auto;
}

.signup-form span {
  display: block;
  margin-bottom: 12px;
}

.signup-form input {
  padding: 8px 58px 0 30px;
  font-size: 1.2rem;
  color: #777;
  width: 498px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #b8c6f8;
  background: #f4feff;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) {
  .signup-form {
    margin: 10% auto;
    padding: 20px;
    width: 390px;
    border-radius: none;
    background: none;
    box-shadow: none;
  }
  .signup-form input {
    color: #777;
    width: 300px;

    background: #fff;
  }
}

.project-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem 2rem 1rem 2rem;
  cursor: pointer;
}

.projectimg {
  width: 100%;
}

.project-container:hover {
  background: #f3f3f3;
}
.project-description {
  margin: 2rem 0;
}

.projecttitle {
  font-size: 1rem;
}

.projectdescription {
  font-size: 0.7rem;
}

.library {
  position: fixed;
  top: 0;
  left: 0;
  width: 20rem;
  height: 100%;
  background: #446df6;
  box-shadow: 18px 19px 41px 0px rgba(59, 112, 102, 0.1);
  overflow: scroll;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: all 0.5s ease;
  opacity: 0;
}

.libraryh2 {
  padding: 2rem;
}

.selected {
  background-color: #f3f3f3;
}

.active-library {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  opacity: 1;
}

.navbar-mob ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menu-mob {
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.4rem;
  margin-top: 3rem;
}

.btn_logout-mob {
  color: #4c4c4c !important;

  background-color: #fff;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 40px;
  padding: 5px 15px;
  border: 2px solid #fff;
}
.logo-mob {
  width: 8rem;
  margin-top: 2rem;
  margin-left: 40px;
}

.container {
  background-color: #f4feff;
}

/* navbar styles */

.navbar {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  max-width: 1200px;
  grid-gap: 37px;
  gap: 37px;
  padding: 15px 0;
  margin: 0 auto;
}

ul {
  grid-area: 1 / 4 / 2 / 13;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.logo {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-area: 1 / 1 / 2 / 3;
}

li a {
  color: #2e2e2e;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.2rem;
  margin-left: 40px;
}

li a:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: #446df6;
          text-decoration-color: #446df6;
  color: #446df6;

  text-decoration-thickness: 4px;
  text-underline-offset: 10px;
  transition-timing-function: ease-in;
  transition: 0.8s;
}

li a:active {
  text-decoration: underline;
  -webkit-text-decoration-color: #446df6;
          text-decoration-color: #446df6;
  color: #446df6;
}

.logot {
  display: flex;
  justify-content: flex-start;
  margin-left: 40px;
  cursor: pointer;
  text-decoration: underline;
  color: #446df6;
  font-size: 1.2rem;
  font-weight: 600;
}

.btn_logout {
  color: #446df6;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 40px;
  padding: 5px 15px;
  border: 2px solid #446df6;
}

.btn_logout:hover {
  border: 0;

  color: #fff;
  text-decoration: none;
  background-color: #446df6;
  padding: 5px 15px;
  border: 2px solid #446df6;
}
.bicon {
  width: 3rem;
  padding-left: 3rem;
  cursor: pointer;
  display: none;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) {
  .navbar {
    width: 390px;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 5px;
    gap: 5px;
    padding: 15px 0;
    margin: 0 auto;
  }

  .logo {
    width: 130px;
    grid-area: 1 / 1 / 2 / 3;
  }
  .menu {
    display: none;
  }
  .bicon {
    width: 3rem;
    cursor: pointer;
    display: block;
    grid-area: 1 / 5 / 2 / 7;
  }
  .btn_logout {
    display: none;
  }
}

/* navbar styles */

.fcontainer {
  /* position: fixed; */
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #f4feff;
}

/* navbar styles */

.footer {
  max-width: 1200px;
  padding: 2% 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.flogo {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
}
.footer .chuchos {
  color: #4c4c4c;
  font-size: 1rem;
}
.footer p {
  color: #4c4c4c;
  font-size: 1rem;
  font-weight: 500;
}
.copyright {
  text-align: right;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) {
  .footer {
    width: 390px;
    margin-bottom: 10%;
  }
  .contactData {
    text-align: right;
  }
  .copyright {
    display: none;
  }
}

