/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
/* base styles */
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  font-family: 'Poppins', sans-serif;
  height: 100vh;
  margin: 0;
}
.App {
  font-size: 62.5%;
}
ul,
li,
p,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
  color: #4c4c4c;
  font-family: 'Poppins', sans-serif;
}
ul {
  list-style-type: none;
}
.btn {
  font-family: 'Poppins', sans-serif;
  background: #446df6;
  padding: 16px 36px;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  font-size: 1.25rem;
  border: none;
  text-decoration: none;
}

.btn_secundary {
  font-family: 'Poppins', sans-serif;
  background: #446df6;
  padding: 16px 36px;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  font-size: 1.25rem;
  border: none;
  text-decoration: none;
}

.btn:hover {
  background: #905cff;
  color: #fff;
}

.btn_secundary:hover {
  background: #905cff;
  color: #fff;
}
.btn:active {
  background: #4d0cd6;
  color: #fff;
}
.btn_secundary:active {
  background: #4d0cd6;
  color: #fff;
}
.title {
  font-family: 'Abril Fatface', sans-serif;
  font-weight: 900;
  font-size: 3.5rem;
  color: #446df6;
}
.subtitle {
  font-size: 3.6rem;
  color: #4c4c4c;
  font-weight: 700;
}

h3 {
  font-size: 1.2rem;
}

p {
  font-size: 1.2rem;
}

.link {
  color: #446df6;
}

.bajada {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1em;
  color: #4c4c4c;
}

label span {
  font-size: 1.125;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #446df6;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min--moz-device-pixel-ratio: 3),
  only screen and (-o-min-device-pixel-ratio: 3/1),
  only screen and (min-device-pixel-ratio: 3) {
  .title {
    width: 90%;
  }
  .btn,
  .btn_secundary {
    width: 90vw;
  }
}
